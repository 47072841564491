<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="30 0 250 250" style="enable-background:new 0 0 250 250;">
        <path class="st5" d="M151.44,44.768V0.132H98.56v44.636H88.213v79.371c0,4.305,3.49,7.795,7.795,7.795h15.838v117.934h26.308
            V131.934h15.838c4.305,0,7.794-3.49,7.794-7.795V44.768H151.44z M128.902,13.023h9.251v22.454h-9.251V13.023z M111.846,13.023h9.251
            v22.454h-9.251V13.023z"/>
    </icon-base>
</template>

<script>
export default {
    name: 'iconCable',
    components: {
        iconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>
